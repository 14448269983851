
section.services-section {
    .wrap {
        background-color: #404040;
    }
    .h5 {
        font-family: ltc-kennerley, serif;
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        letter-spacing: 0.15em;
    }
    .btn {
        border: 3px solid transparent;
        padding: 13px 25px;
    }
    .btn-outline-white {
        color: #fff;
        border-color: #fff;
    }
    .btn-outline-white:hover {
        color: #fff !important;
        background-color: $secondary !important;
        border-color: $secondary !important;
    }
    a:hover {
        .wrap {
            background-color: $primary;
        }
    }
}