section.hero-section {
    min-height: 300px;
    height: 780px;

    .logo {
        margin-top: 160px;
    }
    .mod {
        min-height: 300px;
        height: 780px;

        .h5 {
            font-family: ltc-kennerley, serif;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.15em;
        }
    }
    @media screen and (max-width: 1600px) {
        & {
            height: 780px;
        }
        .mod {
            height: 780px;
        }
    }
    @media screen and (max-width: 991px) {
        & {
            height: 675px;
        }
        .mod {
            height: 675px;
        }
    }
}