section.testimonials-section {
    &.testimonials {
        blockquote {
            margin: 0 0 2rem;

            p {
                @include media-breakpoint-up(xxl) {
                    & {
                        font-size: 27px;
                        line-height: 39px;
                    }
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 27px;
                    line-height: 39px;
                }
            }

            a {
                color: #fff;

                &:hover {
                    color: #d9d9d9;
                }
            }

            cite {
                span {
                    font-family: ltc-kennerley, serif;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.15em !important;

                    // &::before {
                    //     content: '-';
                    //     position: relative;
                    //     margin-right: 5px;
                    // }
                }
                :nth-child(2) {
                    display: none !important;
                }
                :nth-child(3) {
                    display: none !important;
                }
            }
        }
        
        .testimonial-wrapper {
            border-right: none !important;

            @media screen and (max-width: 991px) {
                & {
                    margin-bottom: 0 !important;
                    margin-top: 0 !important;
                }
            }
            @include media-breakpoint-up(xxl) {
                & {
                    padding: 70px 0 45px;
                }
            }
            @media screen and (max-width: 1720px) {
                & {
                    padding: 70px 0 45px;
                }

            }
            @include media-breakpoint-down(xl) {
                & {
                    padding: 70px 0 45px;
                }
            }
            @include media-breakpoint-down(sm) {
                & {
                    padding: 70px 0 45px;
                }
            }
        }
    }
}