section.contact-form {
    .mod1 {
        margin: 0 0 0 -145px;
    }
    p {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.02em;
        line-height: 32px;
    }
    ::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #26282B;
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }
    .form-control {
        background-color: #E9E9E9;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: #26282B;
        text-transform: none;
        letter-spacing: 0.15em;
    }
    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }
    textarea {
        min-height: 142px;
    }
    [id*="btnSend"] {
        max-width: 196px;
    }
    
    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            padding: 0;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-top: 0;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin-bottom: 0;
            padding: 75px 0;
        }
    }
    @media screen and (max-width: 767px) {
        .qc-form-wrapper {
            padding: 75px 0;
        }
    }
    // &.no-services {
    //     @media screen and (min-width: 992px) {    
    //         & {
    //             padding-top: 300px !important;
    //         }
    //     }
        
    //     @media screen and (max-width: 991px) {   
    //         & {
    //             padding-top: 0px !important;
    //         } 
    //     }
    //     &.no-testimonials {
    //         padding-top: 30px !important;
    //     }
    // }
}
